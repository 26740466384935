import { api } from "../../app/services/api";
import { createSelector } from "@reduxjs/toolkit";
import { ControllerType } from "../../constants/constants";

export type VoiceSmsSubscription = "SMS" | "VOICE" | "VOICE_SMS" | null;
export interface GetSitesRequest {
  store_list: number[];
}

export type SiteControllerType = {
  controller_name: string;
  controller_type: ControllerType | null;
  last_updated: string;
  offline: number;
  active_alarm: number;
};

export type SiteControllersType = SiteControllerType[] | null;

export type SiteResponseType = {
  store_num: number;
  store_name: string;
  time_zone: string | null;
  lat: number | null;
  long: number | null;
  store_operator: string;
  store_address: string;
  store_city: string;
  store_state: string;
  leo_version: string | null;
  leo_installed: number; // What about leo_enabled?
  floor_plan_enabled: number;
  terminal_mode_enabled: number;
  store_order: number | null;

  cloud_users_limit: number;
  cloud_contacts: string[] | null;
  cloud_start_date: string | null;
  cloud_end_date: string | null; // this is cloud_end_date in create/edit

  active_alarm_count: number;
  critical_active_alarm_count: number;
  offline: number;

  controllers: SiteControllersType;
  controllers_in_alarm: string[] | null;

  mqtt_path: string;
  mac_address: string | null;
  chain_id: number;
  ingest_data: number;

  voice_sms_subscription: VoiceSmsSubscription;
  sms_receivers_limit: number | null;
  voip_receivers_limit: number | null;
};

export type SiteType = Omit<
  SiteResponseType,
  | "leo_installed"
  | "floor_plan_enabled"
  | "terminal_mode_enabled"
  | "offline"
  | "ingest_data"
  | "lat"
  | "long"
> & {
  leo_installed: boolean;
  floor_plan_enabled: boolean;
  terminal_mode_enabled: boolean;
  offline: boolean;
  ingest_data: boolean;
  latitude: number | null;
  longitude: number | null;
};

export type EditableSiteType = Omit<
  SiteType,
  | "controllers"
  | "controllers_in_alarm"
  | "active_alarm_count"
  | "critical_active_alarm_count"
  | "offline"
  | "leo_version"
>;

export type NewSiteType = Omit<
  SiteType,
  | "store_num"
  | "offline"
  | "controllers"
  | "active_alarm_count"
  | "critical_active_alarm_count"
  | "controllers_in_alarm"
  | "leo_version"
  | "mqtt_path"
> & {
  mqtt_path: string | null;
};

export function convertSiteResponseTypeToSiteType(
  site: SiteResponseType,
): SiteType {
  return {
    ...site,
    leo_installed: site.leo_installed === 1,
    ingest_data: site.ingest_data === 1,
    terminal_mode_enabled: site.terminal_mode_enabled === 1,
    offline: site.offline === 1,
    floor_plan_enabled: site.floor_plan_enabled === 1,
    latitude: site.lat,
    longitude: site.long,
  };
}

export function convertSiteTypeToUpdateSiteRequest(
  site: SiteType,
): CreateOrUpdateSiteRequest {
  return {
    store_name: site.store_name,
    city: site.store_city,
    address: site.store_address,
    state: site.store_state,
    latitude: site.latitude ?? 34.05021375835178,
    longitude: site.longitude ?? -84.75961344314413,
    store_num: site.store_num,
    store_operator: site.store_operator,
    store_order: site.store_order,
    time_zone: site.time_zone ?? "America/New_York",
    cloud_end_date: site.cloud_end_date,
    cloud_start_date: site.cloud_start_date,
    leo_installed: site.leo_installed,
    terminal_mode_enabled: site.terminal_mode_enabled,
    floor_plan_enabled: site.floor_plan_enabled,
  };
}

export function combineEditSiteRequestWithSiteType(
  changedSite: EditSiteRequest,
  site: SiteType,
): SiteType {
  return {
    store_name: changedSite.store_name,
    store_city: changedSite.store_city,
    store_address: changedSite.store_address,
    store_state: changedSite.store_state,
    latitude: changedSite.latitude,
    longitude: changedSite.longitude,
    store_num: changedSite.store_num,
    store_operator: changedSite.store_operator,
    store_order: changedSite.store_order,
    cloud_contacts: changedSite.cloud_contacts,
    time_zone: changedSite.time_zone,
    cloud_users_limit: site.cloud_users_limit,
    cloud_end_date: changedSite.cloud_end_date,
    cloud_start_date: changedSite.cloud_start_date,
    mac_address: changedSite.mac_address,
    ingest_data: changedSite.ingest_data === 1,
    leo_installed: changedSite.leo_installed === 1,
    terminal_mode_enabled: changedSite.terminal_mode_enabled === 1,
    floor_plan_enabled: changedSite.floor_plan_enabled === 1,
    mqtt_path: changedSite.mqtt_path ?? "hl/test",
    active_alarm_count: site.active_alarm_count,
    chain_id: site.chain_id,
    controllers: site.controllers,
    controllers_in_alarm: site.controllers_in_alarm,
    critical_active_alarm_count: site.critical_active_alarm_count,
    leo_version: site.leo_version,
    offline: site.offline,
    sms_receivers_limit: changedSite.sms_receivers_limit,
    voip_receivers_limit: changedSite.voip_receivers_limit,
    voice_sms_subscription: changedSite.voice_sms_subscription,
  };
}

export function convertNewSiteTypeToCreateSiteRequest(
  site: NewSiteType,
): CreateSiteRequest {
  return {
    ...site,
    ingest_data: site.ingest_data ? 1 : 0,
    leo_installed: site.leo_installed ? 1 : 0,
    terminal_mode_enabled: site.terminal_mode_enabled ? 1 : 0,
    floor_plan_enabled: site.floor_plan_enabled ? 1 : 0,
  };
}

// I need to do some thinking on how I will feed sites in for editing and creation
export type EditSiteRequest = Omit<
  SiteResponseType,
  | "lat"
  | "long"
  | "offline"
  | "leo_version"
  | "controllers"
  | "controllers_in_alarm"
  | "chain_id"
  | "active_alarm_count"
  | "critical_active_alarm_count"
  | "mqtt_path"
> & {
  // I think this is correct
  latitude: number | null;
  longitude: number | null;
  mqtt_path: string | null;
};

export type CreateSiteRequest = Omit<EditSiteRequest, "store_num"> & {
  chain_id: number;
};

export type CreateOrUpdateSiteRequest = {
  store_num: number; // not editable
  store_name: string;
  city: string;
  state: string;
  address: string;
  time_zone: string;
  store_operator: string;
  store_order: number | null;
  longitude: number;
  latitude: number;
  cloud_end_date: string | null;
  cloud_start_date: string | null;

  leo_installed: boolean;
  floor_plan_enabled: boolean;
  terminal_mode_enabled: boolean;
};

export type CreateOrUpdateSiteResponse = Omit<
  CreateOrUpdateSiteRequest,
  "city" | "state" | "address"
> & {
  store_city: string;
  store_state: string;
  store_address: string;
};

export type GetSitesResponse = SiteType[];

export type SitesGroupedByOperator = {
  group: string;
  sites: SiteType[];
}[];

export type GetStoreMinimalResponse = Omit<
  SiteType,
  | "lat"
  | "long"
  | "cloud_contacts"
  | "cloud_expiration_date"
  | "cloud-start_date"
  | "controllers"
  | "floor_plan_enabled"
  | "offline"
  | "active_alarm_count"
  | "terminal_mode_enabled"
>[];

export type StoresWithActiveAlarmCount = {
  storeNum: string;
  numActiveAlarms: number;
};

export type GetStoresWithActiveAlarmsCountResponse =
  StoresWithActiveAlarmCount[];

export type GetTodaysAlarmActivityResponse = {
  count: number;
  storeId: number;
}[];

export type ActivationDateType = {
  activationDate: Date;
  storeName: string;
};

export type ChainEndDatesType = {
  chainId: number;
  chainName: string;
  contactEmail: string | null;
  daysDataRetention: number;
  endDate: string;
  offlineDuration: number;
  siteUrl: string;
  startDate: string;
};

export type GetChainEndDatesResponse = ChainEndDatesType[];

export type GetActivationDatesResponse = ActivationDateType[];

export type GetOfflineSitesResponse = {
  store_num: number;
  offline_date: string; // yyyy-mm-dd hh:mm:ss
  last_communicated: string | null;
}[];
export type GetOfflineSitesHistoryResponse = {
  store_num: number;
  offline_date: string; // yyyy-mm-dd hh:mm:ss
  rtn_date: string | null;
}[];

export type CreateSiteResponse = {
  store_num: number;
};
export type EditSiteResponse = {
  status: "success" | "failed";
};

export type SiteUser = {
  user_id: number;
  user_name: string;
  display_name: string | null;
  user_permissions: number[] | null;
};
export type GetSiteUsersRequest = {
  store_num: number;
};
export type GetSiteUsersResponse = SiteUser[];

export type AddSiteUserRequest = GetSiteUsersRequest & {
  email_address: string;
};
export type EditSiteUserRequest = GetSiteUsersRequest & {
  user_id: number;
  user_permissions: string[];
};
export type DeleteSiteUserRequest = GetSiteUsersRequest & {
  user_id: number;
};

export type SiteUserPermission = {
  permission_id: number;
  permission_name: string;
  permission_category: string;
  user_optional: number;
  required_permissions: string | null;
};

export type GetSitePermissionsResponse = SiteUserPermission[];

export type Chain = {
  chain_id: number;
  chain_name: string;
  username: string | null;
  data_retention_days: number;
  sms_phone_numbers: string[] | null;
  voice_phone_numbers: string[] | null;
};
export type GetChainsResponse = Chain[];

export type CreateChainRequest = Omit<Chain, "chain_id" | "username">;
export type EditChainRequest = CreateChainRequest & { chain_id: number };

export type DeleteChainRequest = { chain_id: number };

export type CreateLeoRequest = {
  leo_name: string | null;
  mac_address: string | null;
  chain_id: number;
  cloud_users_limit: number;
  ingest_data: boolean;
  voice_sms_subscription: VoiceSmsSubscription;
  sms_receivers_limit: number | null;
  voip_receivers_limit: number | null;
};
export type EditLeoRequest = CreateLeoRequest & { store_num: number };

export type CreateLeoResponse = {
  store_num: number;
  mac_address: string | null;
  chain_id: number;
  ingest_data: number;
  voice_sms_subscription: VoiceSmsSubscription;
  sms_receivers_limit: number;
  voip_receivers_limit: number;
  new_leo: boolean;
};

export type UpdateCloudContactsRequest = {
  store_num: number;
  email_address: string[];
};

/**
 * @description LEO Alert Receiver for LEO Alert (Plivo) feature
 */
export type AlarmReceiver = {
  store_num: number;
  id: number;
  contact_name: string;
  phone_number: string;
  phone_carrier: string;
  message_type: Exclude<VoiceSmsSubscription, null>;
  call_priority: number | null;
};
export type GetAlarmReceiversResponse = Omit<AlarmReceiver, "store_num">[];

export type NewAlarmReceiver = Omit<AlarmReceiver, "id">;

export type UpdateAlarmReceiver = {
  store_num: number;
  alarm_receivers: AlarmReceiver[];
};

export type CreateAlarmReceiversRequest = {
  store_num: number;
  alarm_receivers: NewAlarmReceiver[];
};
export type UpdateAlarmReceiversRequest = UpdateAlarmReceiver;

export type DeleteAlarmReceiverRequest = {
  store_num: number;
  phone_number: string;
};

export type AlarmFilter = {
  id: number;
  store_num: number;
  controller_regex: AlarmFilterRegex;
  controller_type_regex: AlarmFilterRegex;
  alarm_source_regex: AlarmFilterRegex;
  alarm_text_regex: AlarmFilterRegex;
  controller: string | null;
  controller_type: string | null;
  alarm_source: string | null;
  alarm_text: string | null;
};
export type GetAlarmFiltersRequest = {
  store_num: number;
};
export type GetAlarmFiltersResponse = AlarmFilter[];
export type AlarmFilterRegex =
  | "ANY"
  | "BEGINS WITH"
  | "ENDS WITH"
  | "CONTAINS"
  | "EXACT";
export type CreateAlarmFilterRequest = Omit<AlarmFilter, "id">;
export type UpdateAlarmFilterRequest = AlarmFilter;

export type DeleteAlarmFilterRequest = { id: number; store_num: number };

export type GetGenericAlarmFiltersResponse = {
  controller_type_regex: AlarmFilterRegex;
  controller_type: string | null;
  controller_name_regex: AlarmFilterRegex;
  controller_name: string | null;
  alarm_source_regex: AlarmFilterRegex;
  alarm_source: string | null;
  alarm_text_regex: AlarmFilterRegex;
  alarm_text: string | null;
}[];

export type AddUserToMultipleSitesRequest = {
  user_id: number;
  user_store_permissions: { store_num: number; user_permissions: number[] }[];
};
export type AddUserToMultipleSitesResponse = unknown;

export type PreCheckAddUserToMultipleSitesResponse = {
  store_num: number;
  user_permissions: {
    user_id: number;
    permissions: number[];
  }[];
}[];

export const sitesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<GetSitesResponse, GetSitesRequest>({
      query: ({ store_list }) => ({
        url: `stores/details`,
        method: "POST",
        body: {
          store_list,
        },
      }),
      providesTags: ["Sites"],
      // providesTags: (result, error, arg) => {
      //     // By setting this up properly we should be able to
      //     const listTag = { type: 'Sites' as const, id: 'LIST' };
      //     if (result) {
      //         return [...result.map((s) => ({ type: 'Sites' as const, id: s.store_num })), listTag];
      //     }
      //     return [listTag];
      // },
      transformResponse(response: SiteResponseType[]) {
        const transformedResponse: GetSitesResponse = [];
        for (const site of response) {
          if (site.controllers) {
            // Sort Controllers
            site.controllers.sort((a, b) => {
              if (a.offline === 1) {
                if (b.offline === 1) {
                  return 0;
                } else {
                  return 1;
                }
              }
              if (b.offline === 1) {
                return -1;
              }

              return a.controller_name.localeCompare(
                b.controller_name,
                undefined,
                {
                  numeric: true,
                },
              );
            });
          }
          transformedResponse.push(convertSiteResponseTypeToSiteType(site));
        }

        return transformedResponse.sort((a, b) => {
          return a.store_name.localeCompare(b.store_name, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
      },
      // I plan on implementing the functions below to control the cache in a better manner (keep a single cache and update it with single store queries etc. Improve How often and how much I request from from this endpoint)
      // serializeQueryArgs: ({endpointDefinition, endpointName,queryArgs}) => {
      //     // console.log({endpointDefinition})
      //     // console.log({queryArgs}),
      //     // console.log({endpointName})
      //     return endpointName
      // },
      // merge(currentCacheData, responseData, otherArgs) {
      //     console.log({currentCacheData})
      //     console.log({responseData})
      //     console.log({otherArgs})
      //     responseData.forEach((site) => {
      //         const cachedSiteIndex = currentCacheData.findIndex((cSite) => cSite.store_num === site.store_num);
      //         if (cachedSiteIndex > -1) {
      //             currentCacheData[cachedSiteIndex] = site;
      //         } else {
      //             currentCacheData.push(site);
      //         }
      //     })
      // },
      // forceRefetch(params) {
      //     console.log(params)
      //     if (params.currentArg !== params.previousArg) {
      //         return true
      //     }
      //     return false
      // }
    }),
    getSitesMinimal: builder.query<GetStoreMinimalResponse, GetSitesRequest>({
      query: ({ store_list }) => ({
        url: "stores/details-minimal",
        method: "POST",
        body: {
          store_list,
        },
      }),
      providesTags: ["Sites"],
    }),
    getOfflineSites: builder.query<GetOfflineSitesResponse, GetSitesRequest>({
      query: ({ store_list }) => ({
        url: "stores/offline-active",
        method: "POST",
        body: { store_list },
      }),
    }),
    getOfflineSitesHistory: builder.query<
      GetOfflineSitesHistoryResponse,
      GetSitesRequest
    >({
      query: ({ store_list }) => ({
        url: "stores/offline-historical",
        method: "POST",
        body: { store_list },
      }),
    }),
    getSitesPermissions: builder.query<GetSitePermissionsResponse, void>({
      query: () => ({
        url: "stores/permissions",
        method: "GET",
      }),
      keepUnusedDataFor: 7200, // this shouldn't be requested more than once
      // and we should end up placing it into a slice with some selectors and helper functions to handle permissions
    }),

    // createSite: builder.mutation<CreateSiteResponse, CreateSiteRequest>({
    //   query: (arg) => ({
    //     url: "stores/store",
    //     method: "PUT",
    //     body: arg,
    //   }),
    //   invalidatesTags: ["Sites"],
    // }),
    // editSite: builder.mutation<EditSiteResponse, EditSiteRequest>({
    //   query: (body) => ({
    //     url: "stores/store",
    //     method: "PATCH",
    //     body,
    //   }),
    //   invalidatesTags: ["Sites"],
    //   // THis Optimistic update is working as expected.
    //   // Updates the cahces sites
    //   async onQueryStarted(site, { dispatch, queryFulfilled, ...api }) {
    //     const state = api.getState();
    //     const site_list = (state as RootState).auth.siteList;
    //     const patchResult = dispatch(
    //       sitesApi.util.updateQueryData(
    //         "getSites",
    //         { store_list: site_list },
    //         (sites) => {
    //           const siteIndex = sites.findIndex(
    //             (v) => v.store_num === site.store_num,
    //           );
    //           if (siteIndex > -1) {
    //             sites[siteIndex] = combineEditSiteRequestWithSiteType(
    //               site,
    //               sites[siteIndex],
    //             );
    //           }
    //         },
    //         false,
    //       ),
    //     );
    //     try {
    //       await queryFulfilled;
    //     } catch {
    //       patchResult.undo();
    //     }
    //   },
    // }),
    deleteSite: builder.mutation<EditSiteResponse, { store_num: number }>({
      query: (body) => ({
        url: "stores/store",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Sites"],
    }),

    getSiteUsers: builder.query<GetSiteUsersResponse, GetSiteUsersRequest>({
      query: (body) => ({
        url: "stores/users",
        method: "POST",
        body,
      }),
      transformResponse(
        baseQueryReturnValue: GetSiteUsersResponse,
        _meta,
        _arg,
      ) {
        const returnVal: SiteUser[] = [];
        for (const user of baseQueryReturnValue) {
          // (user.user_permission as number[])= (user.user_permission as string[]).map((num) => +num)
          returnVal.push({
            user_id: user.user_id,
            user_name: user.user_name,
            display_name: user.display_name,
            user_permissions: user.user_permissions?.map((str) => +str) ?? null,
          });
        }
        return returnVal;
      },
      providesTags: ["SiteUsers"],
    }),
    addSiteUser: builder.mutation<unknown, AddSiteUserRequest>({
      query: (body) => ({
        url: "stores/users",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["SiteUsers"],
    }),
    editSiteUser: builder.mutation<unknown, EditSiteUserRequest>({
      query: (body) => ({
        url: "stores/users",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["SiteUsers"],
    }),
    deleteSiteUser: builder.mutation<unknown, DeleteSiteUserRequest>({
      query: (body) => ({
        url: "stores/users",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["SiteUsers"],
    }),

    getChains: builder.query<GetChainsResponse, void>({
      query: () => ({
        url: "stores/chains",
        method: "GET",
      }),
      providesTags: ["Chains"],
    }),
    createChain: builder.mutation<unknown, CreateChainRequest>({
      query: (body) => ({
        url: "stores/chains",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Chains"],
    }),
    editChain: builder.mutation<unknown, EditChainRequest>({
      query: (body) => ({
        url: "stores/chains",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Chains"],
    }),
    deleteChain: builder.mutation<unknown, DeleteChainRequest>({
      query: (body) => ({
        url: "stores/chains",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Chains"],
    }),

    createLEO: builder.mutation<CreateLeoResponse, CreateLeoRequest>({
      query: (body) => ({
        url: "stores/leo",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Sites", "UnregisteredLeos", "UnknownLeos"],
    }),
    editLEO: builder.mutation<unknown, EditLeoRequest>({
      query: (body) => ({
        url: "stores/leo",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Sites", "UnregisteredLeos", "UnknownLeos"],
    }),
    createOrUpdateSite: builder.mutation<
      CreateOrUpdateSiteResponse,
      CreateOrUpdateSiteRequest
    >({
      query: (body) => ({
        url: "stores/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Sites"],
    }),
    updateCloudContacts: builder.mutation<unknown, UpdateCloudContactsRequest>({
      query: (body) => ({
        url: "stores/cloud-contacts",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Sites"],
    }),
    // ALARM RECEIVERS
    getAlarmReceivers: builder.query<
      GetAlarmReceiversResponse,
      { store_num: number }
    >({
      query: (body) => ({
        url: "plivo/alarm-receivers",
        method: "POST",
        body,
      }),
      providesTags: ["AlarmReceivers"],
    }),
    createAlarmReceivers: builder.mutation<
      unknown,
      CreateAlarmReceiversRequest
    >({
      query: (body) => ({
        url: "plivo/alarm-receivers",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["AlarmReceivers"],
    }),
    updateAlarmReceivers: builder.mutation<
      unknown,
      UpdateAlarmReceiversRequest
    >({
      query: (body) => ({
        url: "plivo/alarm-receivers",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["AlarmReceivers"],
    }),
    deleteAlarmReceiver: builder.mutation<unknown, DeleteAlarmReceiverRequest>({
      query: (body) => ({
        url: "plivo/alarm-receivers",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["AlarmReceivers"],
    }),
    // ALARM FILTERS
    getAlarmFilters: builder.query<
      GetAlarmFiltersResponse,
      GetAlarmFiltersRequest
    >({
      query: (body) => ({
        url: "plivo/alarm-filter",
        method: "POST",
        body,
      }),
      providesTags: ["AlarmFilters"],
    }),
    createAlarmFilter: builder.mutation<unknown, CreateAlarmFilterRequest>({
      query: (body) => ({
        url: "plivo/alarm-filter",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["AlarmFilters"],
    }),
    updateAlarmFilter: builder.mutation<unknown, UpdateAlarmFilterRequest>({
      query: (body) => ({
        url: "plivo/alarm-filter",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["AlarmFilters"],
    }),
    deleteAlarmFilter: builder.mutation<
      unknown,
      { id: number; store_num: number }
    >({
      query: (body) => ({
        url: "plivo/alarm-filter",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["AlarmFilters"],
    }),

    getGenericAlarmFilters: builder.query<GetGenericAlarmFiltersResponse, void>(
      {
        query: () => ({
          url: "plivo/generic-alarm-filters",
          method: "GET",
        }),
      },
    ),

    addUserToMultipleSites: builder.mutation<
      AddUserToMultipleSitesResponse,
      AddUserToMultipleSitesRequest
    >({
      query: (body) => ({
        url: "stores/multi-user",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SiteUsers"],
    }),
    preCheckAddUserToMultipleSites: builder.mutation<
      PreCheckAddUserToMultipleSitesResponse,
      { store_list: number[] }
    >({
      query: (body) => ({
        url: "stores/pre-check-multi-user",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetSitesQuery,
  useLazyGetSitesQuery,
  useGetSitesMinimalQuery,

  useCreateOrUpdateSiteMutation,
  useDeleteSiteMutation,

  useCreateLEOMutation,
  useEditLEOMutation,
  useUpdateCloudContactsMutation,

  useGetChainsQuery,
  useCreateChainMutation,
  useEditChainMutation,
  useDeleteChainMutation,

  useGetSiteUsersQuery,
  useAddSiteUserMutation,
  useEditSiteUserMutation,
  useDeleteSiteUserMutation,
  useAddUserToMultipleSitesMutation,
  usePreCheckAddUserToMultipleSitesMutation,

  useGetSitesPermissionsQuery,

  useGetOfflineSitesQuery,
  useGetOfflineSitesHistoryQuery,

  useGetAlarmReceiversQuery,
  useCreateAlarmReceiversMutation,
  useUpdateAlarmReceiversMutation,
  useDeleteAlarmReceiverMutation,

  useGetAlarmFiltersQuery,
  useCreateAlarmFilterMutation,
  useUpdateAlarmFilterMutation,
  useDeleteAlarmFilterMutation,

  useGetGenericAlarmFiltersQuery,
} = sitesApi;

export const selectFilteredSitesSelector = createSelector(
  [
    (res: { data: GetSitesResponse }) => res.data,
    (_, filteredSiteList: number[]) => filteredSiteList,
  ],
  (data, filteredSiteList) =>
    data?.filter((site) => filteredSiteList.includes(site.store_num)) ??
    ([] as GetSitesResponse),
);
