import { useImperativeHandle, forwardRef, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectShowExpiredStoresDialog,
  closeExpiredStoresDialog,
  selectExpiresStoresList,
} from "../../features/view/viewSlice";

type Ref = {
  focus: () => void;
} | null;
interface Props {
  handleClose: () => void;
}

const ExpiredStoresContent = forwardRef<Ref, Props>(({ handleClose }, ref) => {
  const expiredStores = useAppSelector(selectExpiresStoresList);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    focus() {
      buttonRef.current?.focus({ preventScroll: true });
    },
  }));

  return (
    <>
      <DialogTitle color="warning.main">
        The following subscription{expiredStores.length > 1 ? "s" : ""}{" "}
        {expiredStores.length > 1 ? "have" : "has"} expired
      </DialogTitle>
      <DialogContent dividers>
        <Grid container flex="1">
          {expiredStores.map((sto, i) => {
            return (
              <Grid
                key={sto.storeNum}
                size={12}
                display="flex"
                flexDirection="row"
              >
                <Typography>
                  {i + 1}. <strong>{sto.storeName}</strong> has expired on{" "}
                  {sto.expirationDate}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <DialogContentText marginTop={1} textAlign="center">
          Please contact Hunter Liberty Customer Service at{" "}
          <Link href="tel:+1(678)608-0204">(678) 608-0204</Link> to renew your
          subscription.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            ":focus": {
              boxShadow: "0px 0px 19px 1px rgba(232,255,61,1)",
            },
          }}
          ref={buttonRef}
          variant="contained"
          onClick={handleClose}
        >
          OKAY
        </Button>
      </DialogActions>
    </>
  );
});

const ExpiredStoresDialog = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectShowExpiredStoresDialog);

  const buttonHandle = useRef<Ref>(null);

  const handleClose = () => {
    dispatch(closeExpiredStoresDialog());
  };

  const onClose = () => {
    // highlight acknowledge Button by focus
    buttonHandle.current?.focus();
  };

  return (
    <Dialog open={show} onClose={onClose} scroll="paper">
      <ExpiredStoresContent ref={buttonHandle} handleClose={handleClose} />
    </Dialog>
  );
};

export default ExpiredStoresDialog;
