import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getCookie, setCookie } from "../../utils/cookieHandlers";

export type MyPointStyleType =
  | false
  | "circle"
  | "cross"
  | "crossRot"
  | "dash"
  | "line"
  | "rect"
  | "rectRounded"
  | "rectRot"
  | "star"
  | "triangle"
  | null;

export type GlobalGraphingOptionsV1 = {
  borderWidth: number | null;
  pointStyle: MyPointStyleType;
  pointRadius: number | null;
  tension: number | null;
} | null;

export type GlobalGraphingOptions = {
  borderWidth: number | null; // Line width
  pointStyle: MyPointStyleType;
  pointRadius: number | null;
  tension: number | null;
  spanGaps: boolean | null;
} | null;

function isOldGlobalGraphingOptions(
  obj: unknown,
): obj is GlobalGraphingOptionsV1 {
  return (
    obj !== undefined &&
    obj !== null &&
    typeof obj === "object" &&
    !("spanGaps" in obj)
  );
}

const GLOBAL_GRAPHING_OPTIONS_COOKIE_NAME = "GlobalGraphingOptions";
const getGlobalGraphingOptionsCookie = (): GlobalGraphingOptions => {
  const cookieValUnparsed = getCookie(GLOBAL_GRAPHING_OPTIONS_COOKIE_NAME);
  if (cookieValUnparsed) {
    try {
      const globalGraphOptions = JSON.parse(cookieValUnparsed) as
        | Exclude<GlobalGraphingOptions, null>
        | Exclude<GlobalGraphingOptionsV1, null>;

      if (isOldGlobalGraphingOptions(globalGraphOptions)) {
        return { ...globalGraphOptions, spanGaps: true };
      }

      return globalGraphOptions;
    } catch (err) {
      console.info("Failed to parse cookie", err);
    }
  }
  return null;
};
const setGlobalGraphingOptionsCookie = (
  globalGraphingOptions: GlobalGraphingOptions,
) => {
  setCookie(
    GLOBAL_GRAPHING_OPTIONS_COOKIE_NAME,
    JSON.stringify(globalGraphingOptions),
  );
};

export type CustomPropertyGraphingOption = {
  property: string;
  borderWidth: number | null; // Line width
  pointStyle: MyPointStyleType;
  pointRadius: number | null;
  pointHoverRadius: number | null;
  tension: number | null;
  backgroundColor: string | null; // Point Color & BarChart Color
  borderColor: string | null; // Line Color
};
export type CustomPropertyGraphingOptions = CustomPropertyGraphingOption[];
export type ControllersState = {
  customPropertyGraphingOptions: CustomPropertyGraphingOptions;
  globalGraphingOptions: GlobalGraphingOptions;
};

const CUSTOM_PROPERTY_GRAPH_OPTIONS_COOKIE_NAME =
  "CustomPropertyGraphingOptions";
const getCustomPropertyGraphingOptionsCookie =
  (): CustomPropertyGraphingOptions => {
    const cookieValUnparsed = getCookie(
      CUSTOM_PROPERTY_GRAPH_OPTIONS_COOKIE_NAME,
    );
    if (cookieValUnparsed) {
      try {
        const customPropertyGraphOptions = JSON.parse(
          cookieValUnparsed,
        ) as CustomPropertyGraphingOptions;
        return customPropertyGraphOptions;
      } catch (err) {
        console.info("Failed to parse cookie", err);
      }
    }
    return [];
  };
const setCustomPropertyGraphingOptionsCookie = (
  customPropertyGraphingOptions: CustomPropertyGraphingOptions,
) => {
  setCookie(
    CUSTOM_PROPERTY_GRAPH_OPTIONS_COOKIE_NAME,
    JSON.stringify(customPropertyGraphingOptions),
  );
};

const initialState: ControllersState = {
  customPropertyGraphingOptions: getCustomPropertyGraphingOptionsCookie(),
  globalGraphingOptions: getGlobalGraphingOptionsCookie(),
};

export const controllersSlice = createSlice({
  name: "controllers",
  initialState,
  reducers: {
    setGlobalGraphingOptions: (
      state,
      action: PayloadAction<GlobalGraphingOptions>,
    ) => {
      state.globalGraphingOptions = action.payload;
      setGlobalGraphingOptionsCookie(state.globalGraphingOptions);
    },
    setCustomPropertyGraphingOptions: (
      state,
      action: PayloadAction<CustomPropertyGraphingOption>,
    ) => {
      const exists = state.customPropertyGraphingOptions.find(
        (v) => v.property === action.payload.property,
      );

      if (exists) {
        //replace
        const index = state.customPropertyGraphingOptions.findIndex(
          (v) => v.property === action.payload.property,
        );
        if (index > -1) {
          state.customPropertyGraphingOptions[index] = action.payload;
        }
      } else {
        // add
        state.customPropertyGraphingOptions.push(action.payload);
      }
      setCustomPropertyGraphingOptionsCookie(
        state.customPropertyGraphingOptions,
      );
    },
    removeCustomPropertyGraphingOption: (
      state,
      action: PayloadAction<string>,
    ) => {
      const index = state.customPropertyGraphingOptions.findIndex(
        (v) => v.property === action.payload,
      );
      if (index > -1) {
        const customPropertyGraphOptionsCopy = JSON.parse(
          JSON.stringify(state.customPropertyGraphingOptions),
        ) as CustomPropertyGraphingOption[];
        customPropertyGraphOptionsCopy.splice(index, 1);
        state.customPropertyGraphingOptions = customPropertyGraphOptionsCopy;
        setCustomPropertyGraphingOptionsCookie(
          state.customPropertyGraphingOptions,
        );
      }
    },
    resetCustomPropertyGraphingOptions: (state) => {
      state.customPropertyGraphingOptions = [];
      setCustomPropertyGraphingOptionsCookie([]);
    },
  },
});

export const {
  setCustomPropertyGraphingOptions,
  removeCustomPropertyGraphingOption,
  resetCustomPropertyGraphingOptions,
  setGlobalGraphingOptions,
} = controllersSlice.actions;

export const selectGlobalGraphingOptions = (state: RootState) =>
  state.controllers.globalGraphingOptions;
export const selectCustomPropertyGraphingOptions = (state: RootState) =>
  state.controllers.customPropertyGraphingOptions;

export default controllersSlice.reducer;
