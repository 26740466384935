import { useImperativeHandle, forwardRef, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectShowSessionExpiredDialog,
  closeSessionExpiredDialog,
} from "../../features/view/viewSlice";

type Ref = {
  focus: () => void;
} | null;
interface Props {
  handleClose: () => void;
}

const SessionExpiredContent = forwardRef<Ref, Props>(({ handleClose }, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    focus() {
      buttonRef.current?.focus({ preventScroll: true });
    },
  }));

  return (
    <>
      <DialogTitle color="warning.main">Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={18} marginTop={1} textAlign="center">
          Your session has expired. Please log back in.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            ":focus": {
              boxShadow: "0px 0px 19px 1px rgba(232,255,61,1)",
            },
          }}
          ref={buttonRef}
          variant="contained"
          onClick={handleClose}
        >
          OKAY
        </Button>
      </DialogActions>
    </>
  );
});

const SessionExpiredDialog = () => {
  const dispatch = useAppDispatch();
  // show dialog via selector
  const show = useAppSelector(selectShowSessionExpiredDialog);

  const buttonHandle = useRef<Ref>(null);

  const handleClose = () => {
    dispatch(closeSessionExpiredDialog());
  };

  const onClose = () => {
    // highlight acknowledge Button by focus
    buttonHandle.current?.focus();
  };

  return (
    <Dialog transitionDuration={0} open={show} onClose={onClose} scroll="paper">
      <SessionExpiredContent ref={buttonHandle} handleClose={handleClose} />
    </Dialog>
  );
};

export default SessionExpiredDialog;
