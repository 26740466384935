import {
  GridCellParams,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import {
  AllSitesAlarmType,
  SiteAlarmType,
  isSiteAlarmType,
} from "../../../features/alarms/alarmsAPI";
import {
  convertJSDateToLocalDateTimeString,
  convertISOToJSDate,
} from "../../../utils/time";
import DataGrid from "../../DataGrid/DataGrid";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
// import AssignAlarmAction from "./AssignAlarmAction";
import useSyncSiteAlarms from "../useSyncSiteAlarms";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AlarmLeoAlertStatus } from "../../../features/alarms/alarmsAPI";
import CircularProgress from "@mui/material/CircularProgress";

type SiteAlarmsProps = {
  alarms: SiteAlarmType[];
  siteId: number;
  isLoading: boolean;
  leoAlertAvailable: boolean;
  syncAlarmsAvailable: boolean;
  refetch: () => void;
  removeTopMargin?: boolean;
};

type AllSitesAlarmsProps = {
  alarms: AllSitesAlarmType[];
  siteId?: never;
  isLoading: boolean;
  leoAlertAvailable?: boolean;
  syncAlarmsAvailable?: never;
  refetch?: never;
  removeTopMargin?: boolean;
};

type Props = SiteAlarmsProps | AllSitesAlarmsProps;

function ActiveAlarmsTable({
  alarms,
  isLoading,
  removeTopMargin,
  siteId,
  refetch,
  syncAlarmsAvailable,
  leoAlertAvailable = false,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const columns: GridColDef<SiteAlarmType | AllSitesAlarmType>[] =
    useMemo(() => {
      return [
        {
          headerName: "Date/Time",
          field: "alarm_date",
          flex: 0.5,
          minWidth: 120,
          align: "left",
          headerAlign: "left",
          valueGetter: (value: string) => {
            return convertISOToJSDate(value);
          },
          valueFormatter: (value: Date) => {
            return convertJSDateToLocalDateTimeString(value);
          },
          type: "dateTime",
        },
        {
          headerName: "Description",
          field: "alarm_text",
          flex: 1,
          minWidth: 200,
        },
        {
          headerName: "Site Name",
          field: "siteName",
          flex: 0.5,
          hideable: true,
          disableExport: siteId !== undefined,
        },
        {
          headerName: "Controller",
          field: "controller_name",
          flex: 0.5,
          minWidth: 150,
        },
        {
          renderHeader: () => (
            <strong>
              {"Source"}
              &nbsp;
              <span>
                <QueryStatsRoundedIcon />
              </span>
            </strong>
          ),
          field: "alarm_source",
          flex: 1,
          minWidth: 200,

          cellClassName: (
            props: GridCellParams<SiteAlarmType | AllSitesAlarmType>,
          ) => {
            if (
              props.row.application === null ||
              props.row.application === "0"
            ) {
              return "";
            }
            return "active-alarm_source-cell";
          },
        },
        {
          headerName: "LEO Alert Sent",
          field: "leoAlertStatus",
          type: "boolean",
          minWidth: 120,
          disableExport: !leoAlertAvailable,
          renderCell: (params) => {
            const value = params.value as AlarmLeoAlertStatus | undefined;
            switch (value) {
              case "SENT":
                return (
                  <CheckRoundedIcon
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "black",
                    }}
                  />
                );
              case "NOT SENT":
                return (
                  <CloseRoundedIcon
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "black",
                    }}
                  />
                );
              case "LOADING":
                return (
                  <CircularProgress size={20} sx={{ color: "primary.main" }} />
                );
              default:
                return "";
            }
          },
        },
        // {
        //   type: "actions",
        //   field: "actions",
        //   maxWidth: 80,
        //   getActions: (params) => {
        //     return [<AssignAlarmAction key={"1"} row={params.row} />];
        //   },
        // },
      ];
    }, [leoAlertAvailable, siteId]);

  const tableData = useMemo(() => {
    if (alarms) {
      return alarms.map((d, i) => ({ ...d, id: i }));
    }

    return [];
  }, [alarms]);

  const fillInGraphingUrl = (
    siteId: number,
    encodedSiteNameAndLocation: string,
    encodedControllerName: string,
    encodedApplication: string,
  ) => {
    return `/site/${siteId}/${encodedSiteNameAndLocation}/controller/${encodedControllerName}/graph/${encodedApplication}`;
  };

  const getPropertiesBasedOnAlarmType = (
    row: SiteAlarmType | AllSitesAlarmType,
    siteId: number,
    encodedControllerName: string,
    encodedApplication: string,
  ) => {
    if (!isSiteAlarmType(row)) {
      const encodedSiteNameAndLocation = encodeURIComponent(
        `${row.siteName} - ${row.siteLocation}`,
      );
      return fillInGraphingUrl(
        siteId,
        encodedSiteNameAndLocation,
        encodedControllerName,
        encodedApplication,
      );
    } else {
      const encodedSiteNameAndLocation = encodeURIComponent(
        row.siteNameAndLocation,
      );
      return fillInGraphingUrl(
        siteId,
        encodedSiteNameAndLocation,
        encodedControllerName,
        encodedApplication,
      );
    }
  };

  const buildGraphingUrl = (
    value: string,
    row: SiteAlarmType | AllSitesAlarmType,
  ) => {
    const encodedControllerName = encodeURIComponent(row.controller_name);
    const siteId = row.siteId;
    if (value.includes(":")) {
      const colonCount = value.split("").filter((s) => s === ":").length;

      if (colonCount === 2) {
        const [_controller, application] = value.split(":");
        const encodedApplication = encodeURIComponent(application);
        return getPropertiesBasedOnAlarmType(
          row,
          siteId,
          encodedControllerName,
          encodedApplication,
        );
      } else {
        const [application] = value.split(":");
        const encodedApplication = encodeURIComponent(application);
        return getPropertiesBasedOnAlarmType(
          row,
          siteId,
          encodedControllerName,
          encodedApplication,
        );
      }
    } else {
      const application = value;
      const encodedApplication = encodeURIComponent(application);
      return getPropertiesBasedOnAlarmType(
        row,
        siteId,
        encodedControllerName,
        encodedApplication,
      );
    }
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params: GridCellParams<SiteAlarmType | AllSitesAlarmType>,
    _event,
    _details,
  ) => {
    if (params.field === "alarm_source") {
      // they clicked the alarm_source cell
      const { value, row } = params;

      if (row.application !== null && row.application !== "0" && value) {
        const url = buildGraphingUrl(value as string, row);

        const startDate = DateTime.fromISO(row.alarm_date)
          .minus({ hours: 1 })
          .toUTC()
          .toISO();
        const endDate = DateTime.now().toUTC().toISO();

        navigate(url, {
          state: {
            graphRequestDates: {
              startDate,
              endDate,
            },
            from: location.pathname,
          },
        });
      }
    }
  };

  const SyncSiteAlarms = useSyncSiteAlarms({ siteId, refetch });

  return (
    <DataGrid
      rows={tableData}
      columns={columns}
      loading={isLoading}
      removeTopMargin={removeTopMargin}
      getRowClassName={(params) => {
        if ((params.row as SiteAlarmType | AllSitesAlarmType).filtered) {
          if (params.indexRelativeToCurrentPage % 2 === 0) {
            return "yellow-row";
          }
          return "yellow-row--odd";
        }
        if (params.indexRelativeToCurrentPage % 2 === 0) {
          return "red-row";
        }
        return "red-row--odd";
      }}
      autoPageSize
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "alarm_date",
              sort: "desc",
            },
          ],
        },
      }}
      columnVisibilityModel={{
        siteName: siteId === undefined,
        leoAlertStatus: leoAlertAvailable,
      }}
      sx={{
        // disable cell selection style
        ".MuiDataGrid-cell:focus": {
          outline: "none",
        },
        // pointer cursor on ALL rows
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
      }}
      onCellClick={handleCellClick}
      quickFilter
      customButtons={
        syncAlarmsAvailable && SyncSiteAlarms ? [SyncSiteAlarms] : []
      }
    />
  );
}

export default ActiveAlarmsTable;
